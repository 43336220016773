import * as React from "react";
import { Helmet } from "react-helmet";
import { useAnalytics } from "../contexts/AnalyticsProvider";

interface Props {
  title: string;
  children: React.ReactNode;
}
export const Page = ({ title, children }: Props) => {
  const { logEvent } = useAnalytics();

  React.useEffect(() => {
    logEvent("Page View", {
      page: title,
    });
  }, [logEvent, title]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};
