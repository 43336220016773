import React from 'react'
import styles from './sectionHeading.module.scss'

type HeadingData = {
    title: string,
    text?: string
}

const SectionHeading = ({title, text} : HeadingData ) => {
    return (
        <div className={styles.text}>
            <h2>{title}</h2>
            {text && <p className={styles.subtitle}>{text}</p>}
        </div>  
)
}

export default SectionHeading