import React from 'react'
import { Page } from './page'
import styles from './../sections/hero.module.scss'
import GraphSection from '../components/layout/graphSection'
import SectionHeading from '../components/layout/sectionHeading'
import Footer from '../components/layout/footer'

const HiddenPage = () => {

    return (
        <Page title="Thank you!">
            <h1 className={styles.title} style={{color: 'white', marginTop: '4rem', textAlign: 'unset'}}>
                <span style={{fontSize: '5rem', fontWeight: 'bold'}}>Thank you!</span>
            </h1>

            <GraphSection style={{marginBottom: 0}}>
                <SectionHeading
                    title="Popularity of Downtime Index Accelerates Next Phase to Co-Creation"
                />
                <p className="size">The public downtime index was born as a fast response to Covid-19 to support decision makers in construction and off-highway. The experiment has run it’s course for now and the public downtime index is no longer available. <a href="https://www.trackunit.com/company/blog/blog-posts/popularity-of-downtime-index-accelerates-next-phase-to-co-creation/" target="_blank" rel="noopener noreferrer" >Read the full story on the blog</a> and follow <a href="https://www.linkedin.com/company/trackunit-a-s/" target="_blank" rel="noopener noreferrer" >Trackunit</a> for news as we co-create the next phase with our community.</p>
                <p className="size">Join the movement<br /> <b>#eliminatedowntime</b></p>
            </GraphSection>

            <Footer>
                The downtime index was built by Trackunit and Trifork in a media partnership with KHL Group and supported by 15 industry players.
            </Footer>
        </Page>
    )
}

export default HiddenPage