import * as React from "react";
import amplitude, { AmplitudeClient } from "amplitude-js";
import { IEventName } from "./Events";

const apiKey = process.env.REACT_APP_AMPLITUDE_KEY;

export type EventProperties = Record<
  string,
  string | number | boolean | Array<string | number>
>;

export interface IAnalyticsContext {
  logEvent(eventName: IEventName, eventProperties?: EventProperties): void;
}

export const AnalyticsContext = React.createContext<IAnalyticsContext | null>(
  null
);

export const AnalyticsProvider: React.FC = ({ children }) => {
  const instance = React.useRef<AmplitudeClient>();

  React.useEffect(() => {
    if (!apiKey) {
      console.debug("Could not find analytics key, skipping.");
      return;
    }
    if (
      !instance.current &&
      amplitude &&
      typeof amplitude.getInstance === "function"
    ) {
      instance.current = amplitude.getInstance();
      instance.current.init(apiKey);
    }
  }, []);

  const logEvent = React.useCallback(
    (eventName: IEventName, eventProperties?: EventProperties) => {
      if (!instance.current) {
        return;
      }
      instance.current.logEvent(eventName, eventProperties);
    },
    []
  );

  return (
    <AnalyticsContext.Provider value={{ logEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = React.useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }

  const { logEvent } = context;
  return {
    logEvent,
  };
};
