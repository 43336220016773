import React, { ReactNode } from 'react'
import styles from './footer.module.scss'


type FooterTypes = {
    children?: ReactNode
}

const Footer = ({children} : FooterTypes) => {
    return(
        <footer className={styles.footer}>
            <div className={styles.inside}>
                {children
                    ? children
                    :<>
                        The Downtime Index is made with ❤&nbsp;&nbsp;by <a href="https://trifork.com/" target="_blank" rel="noopener noreferrer">Trifork</a>, <a href="https://www.trackunit.com/" target="_blank" rel="noopener noreferrer">Trackunit</a>, <a href="https://duckwise.dk/" target="_blank" rel="noopener noreferrer">Duckwise</a>, and <a href="https://www.khl.com/" target="_blank" rel="noopener noreferrer">KHL</a>.
                    </>
                }
            </div>
        </footer>
    )
}

export default Footer