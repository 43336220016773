import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { GRAPHQL_ENDPOINT } from "./endpoints";

import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import { AnalyticsProvider } from "./contexts/AnalyticsProvider";

const client = new ApolloClient({
  uri: GRAPHQL_ENDPOINT,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AnalyticsProvider>
        <App />
      </AnalyticsProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
