import React, {useState, useEffect} from 'react'
import styles from './header.module.scss'
import { Link, RouteComponentProps } from '@reach/router'
import { useAnalytics } from '../../contexts/AnalyticsProvider'

interface HeaderProps extends RouteComponentProps{
    name: String,
    menuItems?: Array<MenuItemsProps>
}

type MenuItemsProps = {
    title: string,
    link: string,
    newTab?: Boolean
}

const Header = ({name, menuItems}: HeaderProps) => {
    const [scrolled, setScrolled] = useState(Boolean)

    const scrollFunc = () => {
        if(window.scrollY < 50){
            setScrolled(false)
        } else {
            setScrolled(true)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", scrollFunc)
        return function cleanup() {
            window.removeEventListener("scroll", scrollFunc)
        }
    }, []);

    return(
        <header className={`${styles.header} ${scrolled ? styles.scrolled : ""}`}>
            <div className={styles.inner}>
                <h2 className={styles.logo}><Link to="/">{name}</Link></h2>
                <nav className={styles.nav}>
                    <ul className={styles.list}>
                        {menuItems?.map((item) => (
                            <li className={styles.item} key={item.title}>
                                <HeaderMenuItem {...item}/>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header

const HeaderMenuItem = ({title, link, newTab}: MenuItemsProps) => {
    const { logEvent } = useAnalytics();

    const isExternalLink = React.useMemo(() => /^https?:\/\//.test(link), [link]);

    const handleOnClick = () => {
        logEvent("Navigate", {
            provider: "Header",
            to: link,
            title
        })
    }

    if (isExternalLink) {
        return <a href={link} onClick={handleOnClick} target={newTab ? "__blank" : ""}>{title}</a>
    }
    return (
        <Link 
            to={link} 
            onClick={handleOnClick} 
            getProps={({isCurrent}) => ({ className: isCurrent ? styles.activeLink : "" })}
        >
            {title}
        </Link>
    )
}