import React, { ReactNode, useState, useEffect, useRef, CSSProperties } from 'react'
import styles from './graphSection.module.scss'

type GraphData = {
    children: ReactNode,
    centerOnScroll?: Boolean,
    style?: CSSProperties
}

const GraphSection = ({children, centerOnScroll = false, style} : GraphData) => {

    const [top, setTop] = useState(Number)
    const sectionRef = useRef<HTMLElement>(null)

    const scrollFunc = () => {
        if (sectionRef !== null) {
            const element = sectionRef.current?.offsetTop
            const height = sectionRef.current?.offsetHeight
            const windowOffset = window.pageYOffset
            const windowHeight = window.innerHeight

            const diff = element && 
                windowOffset - element

            const isTrue = diff && height && diff > 0 && diff < height

            return isTrue && height && diff && setTop(diff / height * windowHeight / 10)
        }
    }

    useEffect(() => {
        centerOnScroll && (
            window.addEventListener("scroll", scrollFunc)
        )

        return function cleanup() {
            window.removeEventListener("scroll", scrollFunc)
        }

    })

    return (
        <section className={`${styles.section} ${centerOnScroll ? "scroll-center" : ""}`} ref={sectionRef} style={style}>
            {children}
            {centerOnScroll ? (
                <>
                    <style dangerouslySetInnerHTML={{
                        __html: [
                        '.scroll-center::after {',
                        `  top: calc(-10rem + ${top}rem)`,
                        '}'
                        ].join('\n')
                        }}>
                    </style>
                  </>
            ) : ""}
        </section>
    )
}

export default GraphSection