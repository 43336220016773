import React from 'react';
import './styles/global.scss'
import Header from './components/layout/header';
import { Router, RouteComponentProps } from "@reach/router"
import {Helmet} from "react-helmet";
import Home from './pages/home';
import Footer from './components/layout/footer';
import Press from './pages/press';
import About from './pages/about';

import favicon from './assets/images/favicon.svg'
import HiddenPage from './pages/hidden';

const menuItems = [
  { title: "Home", link: "/" },
  {
    title: "Blog",
    link: "https://www.trackunit.com/company/eliminate-downtime/downtime-index/",
    newTab: true,
  },
  { title: "Press", link: "/press" },
  { title: "About", link: "/about" },
];

const metaData = {
  defaultTitle: "Downtime Index",
  defaultDescription: "",
};

const homeData = {
  title: "Home",
};

function App() {
  return (
    <div className="App">
      <Helmet
        titleTemplate={`%s - ${metaData.defaultTitle}`}
        defaultTitle={metaData.defaultTitle}
      >
        <meta charSet="utf-8" />
        <meta name="description" content={metaData.defaultDescription} />
        <link rel="icon" type="image/svg+xml" href={favicon} sizes="16x16" />
      </Helmet>
      {/* <Header name={metaData.defaultTitle} menuItems={menuItems}></Header> */}
      <Header name={metaData.defaultTitle} ></Header>
      <main>
        <Router>
          {/* <RouterPage path="/home" pageComponent={<Home data={homeData} />} /> */}
          {/* <RouterPage path="/press" pageComponent={<Press />} /> */}
          {/* <RouterPage path="/about" pageComponent={<About />} /> */}
          <RouterPage path="/" pageComponent={<HiddenPage />} />
        </Router>
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default App;

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;
